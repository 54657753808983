import React, { useState } from 'react'
// import { Switch } from 'antd'
import tw from 'twin.macro'
import ExampleText from './ExampleText'
import { Col1Data, Col2Data, Col3Data } from './ExampleText/textData'

const OurServices: React.FC<{}> = ({}) => {
  const [pricingType, setPricingType] = useState(0)

  const onChange = (checked: boolean) => {
    setPricingType(checked ? 1 : 0)
    const priceSwitch = document.querySelector<HTMLElement>('.ant-switch-handle')
    const width = document.body.clientWidth

    if (priceSwitch) {
      if (checked) {
        if (width <= 1110) {
          priceSwitch.style.insetInlineStart = 'calc(100% - 18px)'
        } else {
          priceSwitch.style.insetInlineStart = 'calc(100% - 26px)'
        }
      } else {
        if (width <= 1110) {
          priceSwitch.style.insetInlineStart = '4px'
        } else {
          priceSwitch.style.insetInlineStart = '6px'
        }
      }
    }
  }

  return (
    <div className="bg-white pt-[94px] pb-12 lg:pt-20 lg:pb-0" id="services">
      <div className="max-w-[1166px] mx-auto flex flex-col items-center">
        {/* Our Services Section */}
        <img className="w-[87px] h-[46.5px] lg:w-[63px] lg:h-[33px]" src="/images/home/ic_leaf_1.svg" alt="leaf" />
        <div className="header pt-1 lg:text-50/16">Our Services</div>

        <div className="our-service-section-cards flex mt-10 w-[1166px] lg:w-full lg:flex-col lg:mt-0 lg:mb-10">
          {/* C1 */}
          <div className="flex flex-col flex-1 items-center text-center pb-4">
            <img
              className="pt-[34px] lg:pt-[32px]"
              src="/images/home/ic_handbooks.svg"
              width={70}
              height={70}
              alt="handbooks-icon"
            />

            <div className="title font-heldaneDisplay pt-[33px]">Handbooks</div>
            <b className="pt-[15px] max-w-[265px] lg:text-sm lg:max-w-[158px] leading-[23px]">
              We think of everything, so you don't have to.
            </b>
            <div className="bg-[#E7E7E7] h-[1px] w-[122px] mt-8"></div>
            <span className="max-w-[300px] mt-8 lg:text-sm text-[15px] lg:max-w-[290px] leading-[20px]">
              We see your employee handbook as a real tool and resource for your team; one which speaks to who your
              company is, your processes and your policies.
            </span>
            <span className="max-w-[300px] mt-6 lg:text-sm text-[15px] lg:max-w-[290px]">
              Our handbooks are digestible and reader-friendly - not filled with legal jargon. The goal is to stay
              compliant while communicating with your team in a way they can understand clearly, regardless of their
              background. Your employee handbook shouldn't be a "necessary evil"!
            </span>
          </div>

          <div className="h-[591px] w-px bg-gray-1/25 mx-14 lg:hidden"></div>

          {/* C2 */}
          <div className="flex flex-col flex-1 items-center text-center pb-4">
            <img
              className="pt-[34px] lg:pt-[32px]"
              src="/images/home/ic_draft.webp"
              width={70}
              height={70}
              alt="draft-icon"
            />

            <div className="title font-heldaneDisplay pt-[33px]">Policies</div>
            <b className="pt-[15px] max-w-[265px] lg:text-sm lg:max-w-[162px] leading-[23px]">
              Partner with us to tackle these projects faster.
            </b>
            <div className="bg-[#E7E7E7] h-[1px] w-[122px] mt-8"></div>
            <span className="max-w-[300px] mt-8 lg:text-sm text-[15px] lg:max-w-[277px] leading-[20px]">
              We're here for other important documents too! Think employment contracts, HIPAA and standalone policies,
              and other employment-related agreements.
            </span>
            <span className="max-w-[300px] mt-6 lg:text-sm text-[15px] lg:max-w-[277px]">
              We'll work to understand your company's specific compliance needs, then create your policy documents with
              readability in mind—all compliant with any relevant federal, state, and local laws.
            </span>
          </div>

          <div className="h-[591px] w-px bg-gray-1/25 mx-14 lg:hidden"></div>

          {/* C3 */}
          <div className="flex flex-col flex-1 items-center text-center pb-4">
            <img
              className="pt-[34px] lg:pt-[32px]"
              src="/images/home/ic_support.svg"
              width={70}
              height={70}
              alt="support-icon"
            />

            <div className="title font-heldaneDisplay pt-[33px]">HR Support</div>
            <b className="pt-[15px] max-w-[265px] lg:text-sm lg:max-w-[220px] leading-[23px]">
              We offer a unique support option for HR teams and leaders.
            </b>
            <div className="bg-[#E7E7E7] h-[1px] w-[122px] mt-8"></div>
            <span className="max-w-[300px] mt-6 lg:text-sm text-[15px] lg:max-w-[298px] leading-[20px]">
              Unique and customizable support options for HR teams and business leaders.
            </span>
            <span className="max-w-[300px] mt-6 lg:text-sm text-[15px] lg:max-w-[298px]">
              Know that our team shares your goals: to tackle HR challenges quicker and more efficiently. For a flat
              monthly fee, you’ll have both phone and email support from a dedicated HR and employment law expert; an
              extension of your own team, ready to work through it together.
            </span>
          </div>
        </div>

        {/* Common Support Section */}
        <div className="common-support-section mt-[59px] w-[1150px] lg:w-[300px] lg:mt-0 bg-[#EDF1EC] rounded-lg">
          <div className="title font-heldaneDisplay pt-[45px] text-center w-[397px] lg:w-[225px] m-auto">
            Common support examples:
          </div>
          <div className="example-section pt-12 pb-16 flex flex-row lg:flex-col justify-around items-start">
            <div className="example-section__col-1 lg:max-w-[300px] pl-0 lg:pl-[31px]">
              {Col1Data.map((e, i) => (
                <div key={i}>
                  <ExampleText text={e.text} isLongText={e.isLongText}></ExampleText>
                </div>
              ))}
            </div>
            <div className="example-section__col-2 lg:max-w-[300px] pl-0 lg:pl-[31px]">
              {Col2Data.map((e, i) => (
                <div key={i}>
                  <ExampleText text={e.text} isLongText={e.isLongText}></ExampleText>
                </div>
              ))}
            </div>
            <div className="example-section__col-3 lg:max-w-[300px] pl-0 lg:pl-[31px]">
              {Col3Data.map((e, i) => (
                <div key={i}>
                  <ExampleText text={e.text} isLongText={e.isLongText}></ExampleText>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Pricing Section */}
        <div id="pricing" className="pt-4 lg:pt-16"></div>
        <img
          className="mt-27 w-10 h-[27px] lg:w-[29px] lg:h-5 lg:mt-12"
          src="/images/home/ic_pricing.webp"
          alt="pricing"
        />
        <div className="h-0.5 bg-green-2 w-[86px] mt-4 lg:w-[62px]"></div>
        <div className="header mt-8 lg:text-50/16 lg:mt-4">Pricing</div>
        <>
          {/* <div className="flex mt-6 gap-x-4 items-center lg:mt-2">
            <b
              className={`text-19/16 lg:text-13/16 ${
                pricingType == 0 ? 'font-bold text-blue-1' : 'font-normal text-gray-2'
              }`}
            >
              Compliance drafting
            </b>
            <Switch checked={pricingType == 1} onChange={onChange} />
            <b
              className={`text-19/16 lg:text-13/16 ${
                pricingType == 1 ? 'font-bold text-blue-1' : 'font-normal text-gray-2'
              }`}
            >
              Support services
            </b>
          </div>
          {pricingType == 0 && (
            <div className="flex justify-between w-[840px] mt-20 h-[155px] lg:h-[420px] lg:mt-8 lg:flex-col lg:items-center lg:gap-y-8 lg:w-full lg:justify-start">
              <div className="flex flex-col  text-center w-[215px] items-center lg:w-full">
                <span className="text-blue-1 text-lg">Employee Handbook</span>
                <div className="flex gap-x-2 mt-1">
                  <span className="text-21/16 text-red-1 pt-1">$</span>
                  <PriceStyle>2,500</PriceStyle>
                </div>
              </div>
              <div className="flex flex-col text-center w-[215px] items-center lg:w-full">
                <span className="text-blue-1 text-lg">State Addendum</span>
                <div className="flex gap-x-2 mt-1">
                  <span className="text-21/16 text-red-1 pt-1">$</span>
                  <PriceStyle>250</PriceStyle>
                  <span className="text-lg text-blue-1 self-end pb-2">/state</span>
                </div>
              </div>
              <div className="flex flex-col text-center w-[215px] items-center lg:w-full">
                <span className="text-blue-1 text-lg">Other Policies</span>
                <div className="flex gap-x-2 mt-1">
                  <span className="text-lg text-blue-1 self-end pb-2">From</span>
                  <span className="text-21/16 text-red-1 pt-1">$</span>
                  <PriceStyle>500</PriceStyle>
                  <span className="text-lg text-blue-1 self-end pb-2">/doc</span>
                </div>
              </div>
            </div>
          )}
          {pricingType == 1 && (
            <div className="flex justify-between w-[840px] mt-20 h-[155px] lg:h-[420px] lg:mt-8 lg:flex-col lg:items-center lg:gap-y-8 lg:w-full lg:justify-start">
              <div className="flex flex-col text-center w-[215px] items-center lg:w-full">
                <span className="text-blue-1 text-lg">0 – 100 Employees</span>
                <div className="flex gap-x-2 mt-1">
                  <span className="text-21/16 text-red-1 pt-1">$</span>
                  <PriceStyle>1,000</PriceStyle>
                  <span className="text-lg text-blue-1 self-end pb-2">/mo</span>
                </div>
              </div>
              <div className="flex flex-col text-center w-[215px] items-center lg:w-full">
                <span className="text-blue-1 text-lg">101-500 Employees</span>
                <div className="flex gap-x-2 mt-1">
                  <span className="text-21/16 text-red-1 pt-1">$</span>
                  <PriceStyle>1,500</PriceStyle>
                  <span className="text-lg text-blue-1 self-end pb-2">/mo</span>
                </div>
              </div>
              <div className="flex flex-col text-center gap-y-3 w-[215px] items-center lg:w-full">
                <span className="text-blue-1 text-lg">Enterprise</span>
                <a className="text-3xl text-red-1 no-underline font-heldaneDisplay hover:text-red-1/80" href="#contact">
                  Contact us*
                </a>
                <div className="mt-[-14px] bg-red-1 h-[1px] ml-6 mr-8 w-[124px]"></div>
                <span className="text-lg text-blue-1 pb-2 mt-1 max-w-[177px]">*unique pricing based on your needs</span>
              </div>
            </div>
          )} */}
        </>
        <div className="pricing-section-cards flex mt-10 w-[1107px] lg:w-full lg:flex-col lg:items-center lg:mt-0 gap-x-[103px] lg:gap-x-0 lg:gap-y-[30px] gap-y-0 lg:pb-[78px]">
          {/* C1 */}
          <div className="pricing-section-cards__card-1 w-[300px] flex flex-col items-center text-center bg-[#EDF6FC] rounded-lg">
            <div className="sale-div w-full h-[31px] rounded-tl-lg rounded-tr-lg"></div>
            <div className="flex flex-col items-center min-h-[565px]">
              <img
                className="card-icon pt-[34px] lg:pt-[32px]"
                src="/images/home/ic_handbooks.svg"
                width={70}
                height={70}
                alt="handbooks-icon"
              />
              <div className="card-title pt-0">
                <PriceTitleStyle>Handbooks</PriceTitleStyle>
              </div>
              <div className="card-line bg-[#979797] h-[1px] w-[179px] mt-6 opacity-[.36]"></div>

              <div className="card-subtitle pt-[30px]">
                <PriceTitleStyle>
                  New <span className="font-normal text-[18px]">handbooks</span>
                </PriceTitleStyle>
              </div>
              <div className="flex flex-col text-center w-[215px] items-center lg:w-full">
                <div className="flex flex-row items-start gap-x-[5px] pt-3">
                  <div className="text-[21px] text-[#89A0DF] relative -top-1">$</div>
                  <div>
                    <BluePriceNumberStyle>4,000*</BluePriceNumberStyle>
                  </div>
                </div>
              </div>
              <div className="card-subtitle pt-[59px]">
                <PriceTitleStyle>
                  Updated <span className="font-normal text-[18px]">handbooks</span>
                </PriceTitleStyle>
              </div>
              <div className="flex flex-col text-center w-[215px] items-center lg:w-full">
                <div className="flex flex-row items-start gap-x-[5px] pt-3">
                  <div className="text-[21px] text-[#89A0DF] relative -top-1">$</div>
                  <div>
                    <BluePriceNumberStyle>2,500*</BluePriceNumberStyle>
                  </div>
                </div>
              </div>
              <div className="pt-[26px] max-w-[115px]">
                <SmallNote>
                  *includes 3 rounds <br></br> of revisions
                </SmallNote>
              </div>
            </div>

            <div className="flex flex-col hover:opacity-80 pt-[24px] pb-[38px]">
              <div className="flex flex-row justify-center">
                <img src="/images/home/ic_calendar.svg" width={25} height={16} alt="ic_calendar" />
                <a
                  className="text-[10.58px] text-blue-1 font-extrabold leading-5 tracking-[1.24px] ml-[15px]"
                  href="https://calendly.com/taylor-airmason"
                  target="_blank"
                >
                  BOOK A CONSULTATION
                </a>
              </div>
              <div className="bg-red-1 h-1 w-[225px] mt-[14px] text-center"></div>
            </div>
          </div>

          {/* C2 */}
          <div className="pricing-section-cards__card-2 w-[300px] flex flex-col items-center text-center bg-[#FCEFED] rounded-lg">
            <div className="sale-div w-full h-[31px] rounded-tl-lg rounded-tr-lg"></div>

            <div className="flex flex-col items-center min-h-[565px]">
              <img
                className="card-icon pt-[34px] lg:pt-[32px]"
                src="/images/home/ic_draft.webp"
                width={70}
                height={70}
                alt="draft-icon"
              />
              <div className="card-title pt-0">
                <PriceTitleStyle>Policies</PriceTitleStyle>
              </div>
              <div className="card-line bg-[#979797] h-[1px] w-[179px] mt-6 opacity-[.36]"></div>
              <div className="card-subtitle pt-[30px]">
                <PriceTitleStyle>
                  Onboarding <span className="font-normal text-[18px]">documents</span>
                </PriceTitleStyle>
              </div>
              <div className="flex flex-col text-center w-[215px] items-center lg:w-full">
                <div className="flex flex-row items-start gap-x-[5px] pt-3">
                  <div className="text-[21px] text-[#EC9688] relative -top-1">$</div>
                  <div>
                    <OrangePriceNumberStyle>1,500*</OrangePriceNumberStyle>
                  </div>
                </div>
              </div>
              <div className="card-subtitle pt-[59px]">
                <PriceTitleStyle>
                  Other <span className="font-normal text-[18px]">policies</span>
                </PriceTitleStyle>
              </div>
              <div className="flex flex-col text-center w-[215px] items-center lg:w-full">
                <div className="flex flex-row items-start gap-x-[5px] pt-[10px]">
                  <div>
                    <SmallOrangePriceNumberStyle>Price upon request</SmallOrangePriceNumberStyle>
                  </div>
                </div>
              </div>
              <div className="pt-[32px] max-w-[199px] ">
                <SmallNote>
                  *includes offer letter, initial agreements such as NDA, Non-Solicit, and welcome letter
                </SmallNote>
              </div>
            </div>

            <div className="flex flex-col hover:opacity-80 pt-[24px] pb-[38px]">
              <div className="flex flex-row justify-center">
                <img src="/images/home/ic_calendar.svg" width={25} height={16} alt="ic_calendar" />
                <a
                  className="text-[10.58px] text-blue-1 font-extrabold leading-5 tracking-[1.24px] ml-[15px]"
                  href="https://calendly.com/taylor-airmason"
                  target="_blank"
                >
                  BOOK A CONSULTATION
                </a>
              </div>
              <div className="bg-red-1 h-1 w-[225px] mt-[14px] text-center"></div>
            </div>
          </div>

          {/* C3 */}
          <div className="pricing-section-cards__card-3 w-[300px] flex flex-col items-center text-center bg-[#EDF1EC] rounded-lg">
            <div className="sale-div w-full h-[31px] bg-[#008060] flex flex-row items-center justify-center gap-x-[11px] rounded-tl-lg rounded-tr-lg">
              <img src="/images/home/ic_mini_star.svg" alt="mini-star" />
              <div className="font-bold text-[13.61px] text-white-1">Save 35% by paying annually!</div>
            </div>

            <div className="flex flex-col items-center min-h-[565px]">
              <img
                className="card-icon pt-[34px] lg:pt-[32px]"
                src="/images/home/ic_support.svg"
                width={70}
                height={70}
                alt="support-icon"
              />
              <div className="card-title pt-0">
                <PriceTitleStyle>HR Support</PriceTitleStyle>
              </div>
              <div className="card-line bg-[#979797] h-[1px] w-[179px] mt-6 opacity-[.36]"></div>

              {/* 0-50 */}
              <>
                <div className="card-subtitle pt-[30px]">
                  <PriceTitleStyle>
                    0-50 <span className="font-normal text-[18px]">employees</span>
                  </PriceTitleStyle>
                </div>
                <div className="flex flex-col text-center w-[215px] items-center lg:w-full">
                  <div className="flex flex-row items-start gap-x-[5px] pt-[14px]">
                    <div className="text-[21px] text-[#008060] relative -top-1">$</div>
                    <div>
                      <GreenPriceNumberStyle>500*</GreenPriceNumberStyle>
                    </div>
                    <SmallUnitText>
                      <div className="text-[#008060]">/mo</div>
                    </SmallUnitText>
                  </div>
                  <SmallNote className="w-[199px]">
                    *annual billing or $750 /mo <br></br> if you pay monthly.
                  </SmallNote>
                </div>
              </>

              {/* 51-500 */}
              <>
                <div className="card-subtitle pt-[30px]">
                  <PriceTitleStyle>
                    51-500 <span className="font-normal text-[18px]">employees</span>
                  </PriceTitleStyle>
                </div>
                <div className="flex flex-col text-center w-[215px] items-center lg:w-full">
                  <div className="flex flex-row items-start gap-x-[5px] pt-[14px]">
                    <div className="text-[21px] text-[#008060] relative -top-1">$</div>
                    <div>
                      <GreenPriceNumberStyle>1,000*</GreenPriceNumberStyle>
                    </div>
                    <SmallUnitText>
                      <div className="text-[#008060]">/mo</div>
                    </SmallUnitText>
                  </div>
                  <SmallNote className="w-[199px]">
                    *annual billing or $1,350 /mo <br></br> if you pay monthly.
                  </SmallNote>
                </div>
              </>

              {/* +500 */}
              <>
                <div className="card-subtitle pt-[30px]">
                  <PriceTitleStyle>
                    500+ <span className="font-normal text-[18px]">employees</span>
                  </PriceTitleStyle>
                </div>
                <div className="flex flex-col text-center w-[215px] items-center lg:w-full">
                  <div className="flex flex-row items-start gap-x-[5px] pt-[14px]">
                    <div className="text-[21px] text-[#008060] relative -top-1">$</div>
                    <div>
                      <GreenPriceNumberStyle>1,500*</GreenPriceNumberStyle>
                    </div>
                    <SmallUnitText>
                      <div className="text-[#008060]">/mo</div>
                    </SmallUnitText>
                  </div>
                  <SmallNote className="w-[199px]">
                    *annual billing or $2,000 /mo <br></br> if you pay monthly
                  </SmallNote>
                </div>
              </>
            </div>

            <div className="flex flex-col hover:opacity-80 pt-[24px] pb-[38px]">
              <div className="flex flex-row justify-center">
                <img src="/images/home/ic_calendar.svg" width={25} height={16} alt="ic_calendar" />
                <a
                  className="text-[10.58px] text-blue-1 font-extrabold leading-5 tracking-[1.24px] ml-[15px]"
                  href="https://calendly.com/taylor-airmason"
                  target="_blank"
                >
                  BOOK A CONSULTATION
                </a>
              </div>
              <div className="bg-red-1 h-1 w-[225px] mt-[14px] text-center"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const PriceStyle = tw.span`text-red-1 text-6.5xl text-center font-heldaneDisplay tracking-normal leading-69/16`
const PriceTitleStyle = tw.span`font-bold text-lg text-[#012D2D] max-w-[100px] m-auto`
const BluePriceNumberStyle = tw.span`font-heldaneDisplay text-[40px] text-[#89A0DF]`
const OrangePriceNumberStyle = tw.span`font-heldaneDisplay text-[40px] text-[#EC9688]`
const GreenPriceNumberStyle = tw.span`font-heldaneDisplay text-[40px] text-[#008060]`
const SmallOrangePriceNumberStyle = tw.span`font-heldaneDisplay text-[18px] text-[#EC9688]`
const SmallNote = tw.div`text-xs text-[#012D2D] italic font-nunitoSans font-semibold leading-[16px]`
const SmallUnitText = tw.div`font-heldaneDisplay text-[21px] self-end`

export default OurServices
