import React, { useEffect, useRef, useState } from 'react'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Slider from 'react-slick'

interface Testimonial {
  description: string,
  author: string,
  image: string,
  width: string,
}

const testimonials: Testimonial[] = [
  {
    description: '“We recently partnered to help us develop an employee handbook. Tackle was absolutely phenomenal to work with throughout the process. They are knowledgeable in employment law, and we appreciated their insights as we had questions about writing multi-state policies. Tackle’s communication was refreshing as we worked through this project. I would highly recommend their services and really enjoyed working with them!”',
    author: 'HR Manager @ CityCast',
    image: '/images/home/testimonial_1.webp',
    width: 'max-w-[510px]'
  }, {
    description: '“I have had the pleasure of working with TackleHR for over a year. Their knowledge of the industry, employment law, and employee relations has brought a lot of value to our company. They provide me with great advice and timely support.”',
    author: 'Office Manager @ Peak Heart & Vascular',
    image: '/images/home/testimonial_2.webp',
    width: 'max-w-[460px]'
  },
  {
    description: '“TackleHR is a real pleasure to work with. They are always ready to take on one or several new projects and turnaround is quick! Delivers quality products in days when others need weeks.”',
    author: 'Chief &nbsp;People Officer @ LURIN',
    image: '/images/home/testimonial_3.webp',
    width: 'max-w-[480px]'
  },
  {
    description: '“Our handbook had gotten to be over 200 pages using the SHRM handbook builder. The voice of the company was lost in the legalese. I found TackleHR on a google search and they were able to help us streamline our handbook to something concise and - dare I say, fun, to read. They are patient with the process and truly offer the back-and-forth collaboration promised.”',
    author: 'HR Operations Manager @ LINQ',
    image: '/images/home/testimonial_4.webp',
    width: 'max-w-[510px]'
  }
]

const Testimonials: React.FC<{}> = ({ }) => {
  const [activeSlide, setActiveSlide] = useState(0)

  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 6000,
    speed: 1000,
    slideToShow: 1,
    slideToScroll: 1,
    swipeToSlide: true,
    pauseOnFocus: true,
    arrows: false,
    beforeChange: (_: number, next: number) => setActiveSlide(next),
  }

  return (
    <div className="flex flex-col bg-blue-1" id="testimonials">
      <div className="h-16 bg-white-1 lg:h-10"></div>
      <div className="w-[930px] mx-auto relative lg:w-full">
        <img
          className="absolute top-[-60px] left-0 w-[105px] h-[102px] lg:top-[-40px] lg:left-[18.5px]"
          src="/images/home/ic_leaf_3.svg"
          width={105}
          height={102}
          alt="leaf"
        />

        <div className="h-[776px] lg:h-[860px]">
          <div className="flex flex-col items-center">
            <img className="mt-14 w-14 h-14 lg:mt-12" src="/images/home/ic_star.svg" alt="testimonials" />
            <div className="mt-4 h-0.5 w-[86px] bg-white-1" />
            <div className="header mt-6 text-white-1 text-70/16 lg:text-50/16">Testimonials</div>
          </div>

          <Slider {...settings}>
            {testimonials.map((testimonial, index) => (
              <div className="!flex justify-between mt-14 px-4 lg:flex-col-reverse lg:items-center lg:mt-1 lg:px-0" key={index}>
                <div className={`${testimonial.width} flex flex-col gap-y-6 lg:max-w-[310px] lg:mr-0 lg:gap-y-8`}>
                  <i
                    className={`text-white-2 text-21/16 font-extralight leading-7 lg:text-sm lg:mt-6 lg:w-[250px] lg:mt-8`}
                    dangerouslySetInnerHTML={{ __html: testimonial.description }}>
                  </i>
                  <span
                    className="font-heldaneDisplay text-21/16 leading-6 text-white-1 lg:text-sm"
                    dangerouslySetInnerHTML={{ __html: testimonial.author }}></span>

                  <div className='flex ml-2 mt-4 gap-x-5 lg:mt-[-12px] lg:ml-1'>
                    {testimonials.map((_, index) => (
                      <div className={`h-[9px] w-[9px] bg-white-1 rounded-lg ${activeSlide == index ? 'opacity-100' : 'opacity-50'}`} key={index}></div>
                    ))}
                  </div>
                </div>
                <img
                  className="w-[348px] h-[228px] lg:mr-8"
                  src={testimonial.image}
                  alt="avatar"
                />
              </div>
            ))}
          </Slider>

        </div>

        <img
          className="absolute bottom-[-24px] left-0 lg:hidden"
          src="/images/home/ic_leaf_7.svg"
          width={87}
          height={46.5}
          alt="leaf"
        />

        <img
          className="absolute hidden bottom-[-24px] left-0 right-0 mx-auto lg:block"
          src="/images/home/ic_leaf_9.svg"
          width={87}
          height={46.5}
          alt="leaf"
        />
      </div>
      <div className="h-16 bg-white-1" />
    </div>
  )
}

export default Testimonials
