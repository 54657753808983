import React from 'react'
import { RouteComponentProps } from '@reach/router'
import Header from './Header'
import WhoWeAre from './WhoWeAre'
import OurServices from './OurServices'
import About from './About'
import Footer from './Footer'
import ContactBanner from './ContactBanner'
import GetInTouch from './GetInTouch'
import Testimonials from './Testimonials'

const Landing: React.FC<RouteComponentProps<any>> = ({}) => {
  return (
    <div>
      <div id="landing-page" className="w-screen flex flex-col bg-white mx-auto">
        <Header />
        <About />
        <WhoWeAre />
        <OurServices />
        <Testimonials />
        <GetInTouch />
        <Footer />
      </div>
      <ContactBanner />
    </div>
  )
}

export default Landing
