import React from 'react'

interface ExampleTestProps {
  text: string
  isLongText: boolean
}

const ExampleText: React.FC<ExampleTestProps> = ({ text = '', isLongText = false }) => {
  return (
    <div className="flex flex-row items-center">
      <img className="mr-[19px] w-[22px] h-[20px]" src="/images/home/ic_checked.svg" alt="checked_icon" />
      <div className="font-bold text-[13px] lg:text-[12px] leading-[34px] pr-1">
        <p className={isLongText ? 'lg:leading-[21px]' : ''}>{text}</p>
      </div>
    </div>
  )
}

export default ExampleText
