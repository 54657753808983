import React from 'react'

const WhoWeAre: React.FC<{}> = ({}) => {
  return (
    <div className="bg-green-1/20 pt-[105px] pb-[95px] lg:pt-[90px]" id="who-we-are">
      <div className="max-w-[1100px] mx-auto flex lg:flex-col lg:items-center lg:text-center">
        <div className="flex-1 flex flex-col lg:items-center">
          <img className="w-[87px] h-[62px] lg:w-[63px] lg:h-[45px]" src="/images/home/ic_leaf.svg" alt="leaf" />
          <div className="header pt-10 lg:text-center lg:text-50/16 lg:pt-4">Who We Are</div>
        </div>
        <div className="flex flex-1 flex-col lg:items-center">
          <div className="title lg:max-w-[290px]">We’re your lifeline; your “phone-a-friend”</div>
          <b className="max-w-[400px] mt-4 lg:max-w-[290px] lg:text-sm">
            TackleHR partners with internal HR teams big and small to support you through the challenges as they arise.
          </b>
          <span className="mt-8 max-w-[427px] lg:max-w-[307px] lg:text-sm">
            Founded by HR professionals, we know what keeps HR teams up at night. We recognize first-hand how helpful it
            is to have a trusted partner on-hand—whether navigating complex situations, poring over compliance, or
            simply tackling the day-to-day challenges together.
          </span>
          <div className="flex flex-col mt-16 max-w-[310px] hover:opacity-80 lg:hidden">
            <div className="ml-5 flex items-center">
              <img src="/images/home/ic_calendar.svg" width={33} height={21} alt="ic_calendar" />
              <a
                className="text-14/16 text-blue-1 font-extrabold leading-5 tracking-[1.65px] ml-5"
                href="https://calendly.com/taylor-airmason"
                target="_blank"
              >
                BOOK A CONSULTATION
              </a>
            </div>
            <div className="bg-red-1 h-1 max-w-[300px] mt-4"></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WhoWeAre
