import { validateEmail } from '@src/utils/utils'
import React, { useState } from 'react'
import { Form, Field } from 'react-final-form'
// import Select, { components, DropdownIndicatorProps } from 'react-select'
// interface DropdownData {
//   label: string
//   value: number | string
// }

// const enquiries: DropdownData[] = [
//   {
//     label: 'General enquiry',
//     value: 'General enquiry',
//   },
// ]

const GetInTouch: React.FC<{}> = ({}) => {
  const [formError, setFormError] = useState<string>('')

  const onSubmit = async (values) => {
    if (checkAllFields(values) != '') {
      return
    }

    const formData = new FormData()

    for (const value of Object.keys(values)) {
      if (typeof values[value] === 'string') {
        formData.append(value, values[value])
      } else if (values[value].label) {
        formData.append(value, values[value].label)
      }
    }

    fetch(process.env.GATSBY_FORMSPREE_API || '', {
      method: 'POST',
      body: formData,
      headers: {
        Accept: 'application/json',
      },
    })
      .then((response) => {
        if (response.ok) {
          setFormError('Thanks for your submission!')
          document.getElementById('get-in-touch')?.scrollIntoView()
        } else {
          response.json().then((data) => {
            setFormError('Oops! There was a problem submitting your message')

            if (Object.hasOwn(data, 'errors')) {
              console.log(data['errors'].map((error) => error['message']).join(', '))
            }
          })
        }
      })
      .catch((error) => {
        console.log(error)
        setFormError('Oops! There was a problem submitting your message')
      })
  }

  const checkAllFields = (values) => {
    let error = ''

    if (values.email && !validateEmail(values.email)) {
      error = 'Please input a valid email address.'
    } else if (!values.name || !values.phone || !values.email || !values.message || !values.subject) {
      error = 'Please complete all fields.'
    } else {
      error = ''
    }
    setFormError(error)
    return error
  }

  // const DropdownIndicator = (props: DropdownIndicatorProps<DropdownData, true>) => {
  //   return (
  //     <components.DropdownIndicator {...props}>
  //       <img src="/images/home/ic_dropdown.svg" />
  //     </components.DropdownIndicator>
  //   )
  // }

  // const SelectAdapter = ({ input, ...rest }) => {
  //   return (
  //     <Select
  //       {...input}
  //       {...rest}
  //       className="form-selector"
  //       classNamePrefix="form-selector"
  //       components={{ DropdownIndicator }}
  //       isSearchable={false}
  //       placeholder="General enquiry"
  //     />
  //   )
  // }

  return (
    <div className="bg-white-1 pt-14 pb-[160px] lg:pt-4 lg:pb-[70px]" id="contact">
      <div className="max-w-[1100px] mx-auto flex flex-col items-center">
        <div className="mt-4.5 max-w-[720px] flex flex-col items-center lg:max-w-full">
          <img className="w-8 h-5" src="/images/home/ic_mail.svg" alt="contact" />
          <div className="h-0.5 w-[86px] bg-red-2 mt-5 lg:w-[33px] lg:mt-[21px]"></div>
          <div className="title text-4xl mt-6 lg:text-3xl" id="get-in-touch">
            Get in touch today
          </div>
          <span className="mt-5 max-w-[300px] text-center lg:text-sm lg:mt-4 lg:max-w-[240px]">
            Reach out for a customized strategy to help tackle your HR needs.
          </span>

          <div className="relative">
            <img
              className="absolute top-1 right-[-40px] z-10 lg:hidden"
              src="/images/home/ic_leafs.svg"
              width={87}
              height={91}
              alt="leafs"
            />
            <img
              className="absolute bottom-0 left-[-48px] z-10 lg:hidden"
              src="/images/home/ic_leafs_1.svg"
              width={87}
              height={91}
              alt="leafs"
            />

            <div className="get-in-touch relative bg-white-1 mt-12 pb-4 shadow-1 h-[730px] w-[720px] z-20 lg:h-full lg:w-full lg:shadow-none lg:mt-0">
              {formError != 'Thanks for your submission!' && (
                <Form
                  onSubmit={onSubmit}
                  render={({ handleSubmit, form, submitting, pristine, values }) => (
                    <form
                      className="pt-[74px] px-[120px] lg:pt-10 lg:px-0 xs1:w-[300px] xs:lg:w-[550px]"
                      autoComplete="off"
                      onSubmit={handleSubmit}
                    >
                      <div className="flex flex-col">
                        <div className="flex justify-evenly gap-x-6 sm:flex-col sm:gap-y-8">
                          <div className="flex flex-1 flex-col">
                            <span className="text-blue-1 text-17/16 lg:text-sm">
                              Name<span className="text-red-1 lg:text-sm">*</span>
                            </span>
                            <Field name="name" component="input" type="text" maxLength="50" />
                          </div>

                          <div className="flex flex-1 flex-col">
                            <span className="text-blue-1 text-17/16 lg:text-sm">Company</span>
                            <Field name="company" component="input" type="text" maxLength="50" />
                          </div>
                        </div>

                        <div className="flex justify-evenly gap-x-6 mt-8 sm:flex-col sm:gap-y-8">
                          <div className="flex flex-1 flex-col">
                            <span className="text-blue-1 text-17/16 lg:text-sm">
                              Phone number<span className="text-red-3 lg:text-sm">*</span>
                            </span>
                            <Field name="phone" component="input" type="tel" maxLength="50" />
                          </div>

                          <div className="flex flex-1 flex-col">
                            <span className="text-blue-1 text-17/16 lg:text-sm">
                              Email<span className="text-red-3 lg:text-sm">*</span>
                            </span>
                            <Field className="max-w-full" name="email" component="input" type="text" maxLength="50" />
                          </div>
                        </div>

                        <span className="text-blue-1 text-17/16 mt-8 lg:text-sm">
                          How can we help you?<span className="text-red-3 lg:text-sm">*</span>
                        </span>
                        <Field name="subject" component="input" type="text" maxLength="200" />

                        <span className="text-blue-1 text-17/16 mt-8 lg:text-sm">
                          Your message<span className="text-red-3 lg:text-sm">*</span>
                        </span>
                        <Field name="message" component="textarea" rows={10} cols={20} maxLength="2000" />

                        <div className="flex mt-8 justify-between gap-x-4 items-center xs1:flex-col xs1:items-start xs1:gap-y-4 xs1:mt-4">
                          <span className="text-red-3 text-sm">{formError == '' ? '*required!' : formError}</span>
                          <button className="w-[276px] h-[46px] bg-blue-1 text-white-1 text-sm font-bold tracking-widest hover:bg-blue-1/95 lg:text-sm xs1:w-[300px]">
                            SUBMIT YOUR MESSAGE
                          </button>
                        </div>
                      </div>
                    </form>
                  )}
                />
              )}
              {formError == 'Thanks for your submission!' && <FormSuccess />}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const FormSuccess = () => {
  return (
    <div className="flex flex-col items-center justify-center h-full w-full lg:h-[320px]">
      <img className="w-[51px] h-[47px] lg:w-9 lg:h-8" src="/images/home/ic_success.svg" />
      <div className="title text-4xl mt-11 lg:text-3xl lg:mt-8">Thanks for your message!</div>
      <span className="mt-3 lg:text-sm lg:mt-2">We will respond to you as soon as possible.</span>
    </div>
  )
}

export default GetInTouch
