import React from 'react'

const About: React.FC<{}> = ({}) => {
  return (
    <div className="bg-white pt-[184px]" id="about">
      <div className="max-w-[1100px] mx-auto flex sm:flex-col">
        <div className="flex-1 flex flex-col lg:items-center lg:text-center md:pb-2">
          <h1 className="header max-w-[390px] lg:max-w-[280px] lg:text-50/16 lg:leading-61/16">Your partner in HR.</h1>
          <span className="pt-6 max-w-[425px] lg:max-w-[283px] lg:text-sm">
            We tackle your compliance challenges, so you can focus on the work that matters most.
          </span>
          <div className="flex flex-col hover:opacity-80 pt-[32px] pb-[46px] w-[225px]">
            <div className="flex flex-row justify-center items-center">
              <img src="/images/home/ic_calendar.svg" width={32} height={30} alt="ic_calendar" />
              <a
                className="text-[10.58px] text-blue-1 font-extrabold leading-5 tracking-[1.24px] ml-[13px]"
                href="https://calendly.com/taylor-airmason"
                target="_blank"
              >
                BOOK A CONSULTATION
              </a>
            </div>
            <div className="bg-red-1 h-1 w-[225px] mt-[8px] text-center"></div>
          </div>
        </div>
        <div className="flex flex-1 lg:text-center">
          <img
            className="w-[520px] h-[355px] sm:w-full md1:w-10/12 lg1:w-full lg:h-full lg1:mr-0 md1:mr-4 sm:h-[245px]"
            src="/images/home/bg_about.svg"
            alt="logo"
          />
        </div>
      </div>
    </div>
  )
}

export default About
