export const Col1Data = [
  {
    text: 'Offer letters',
    isLongText: false,
  },
  {
    text: 'Onboarding contracts',
    isLongText: false,
  },
  {
    text: 'Employment agreements',
    isLongText: false,
  },
  {
    text: 'Remote working policies',
    isLongText: false,
  },
  {
    text: 'Grievance procedures',
    isLongText: false,
  },
  {
    text: 'Artificial intelligence (AI) usage policies**',
    isLongText: true,
  },
  {
    text: 'Code of Conduct/Ethics',
    isLongText: false,
  },
  {
    text: 'Human Rights, Anti-Trafficking & Modern Slavery',
    isLongText: true,
  },
]

export const Col2Data = [
  {
    text: 'IT and Acceptable Use policies',
    isLongText: false,
  },
  {
    text: 'Standard Operating Procedures (SOPs)',
    isLongText: false,
  },
  {
    text: 'Consent forms (media, surveillance etc.)',
    isLongText: false,
  },
  {
    text: 'Health and Safety programs',
    isLongText: false,
  },
  {
    text: 'Emergency and Evacuation plans',
    isLongText: false,
  },
  {
    text: 'Communicable disease policies/procedures',
    isLongText: true,
  },
  {
    text: 'Company property/equipment agreements',
    isLongText: true,
  },
  {
    text: 'Workplace accommodations',
    isLongText: false,
  },
]

export const Col3Data = [
  {
    text: 'HIPAA policies',
    isLongText: false,
  },
  {
    text: 'Drug, Alcohol & Substance Management policies',
    isLongText: true,
  },
  {
    text: 'Privacy and Confidentiality policies',
    isLongText: false,
  },
  {
    text: 'Training documents',
    isLongText: false,
  },
  {
    text: 'State-compliant addenda',
    isLongText: false,
  },
  {
    text: 'Conflicts of Interest policies',
    isLongText: false,
  },
  {
    text: 'Non-disclosure Agreements (NDAs)',
    isLongText: false,
  },
]
